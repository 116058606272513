import { Col, Row } from 'antd';
import Skeleton from 'react-loading-skeleton';
import React, { useState, useMemo } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
export const currencyFormatter = (value, allowedDecimal = 2) => {
    if (!value) {
        return '0';
    }
    const decimalCount = Number(value) % 1 === 0 ? 0 : allowedDecimal;
    const valueString = Number(value).toFixed(decimalCount);
    return valueString
        .toString()
        .replace('.', ',')
        .replace(/^0+/, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        .replace(/[A-Za-z]/g, '');
};
const BillSummary = ({ data = [], total }) => {
    const [collapsedItems, setCollapsedItems] = useState([]);
    const handleToggleCollapse = (index) => {
        setCollapsedItems((state) => state.includes(index) ? state.filter((item) => item !== index) : [...state, index]);
    };
    const isCollapse = (index) => collapsedItems.includes(index);
    // Check if any child or parent is loading
    const isAnyChildLoading = (item) => item.value === 'loading' || (item.childs || []).some((child) => child.value === 'loading');
    // Map data to propagate loading states
    const computedData = useMemo(() => data.map((item) => ({
        ...item,
        value: isAnyChildLoading(item) ? 'loading' : item.value,
    })), [data]);
    // Determine if the total is loading
    const isTotalLoading = useMemo(() => computedData.some((item) => item.value === 'loading'), [computedData]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { gutter: [4, 10] },
            computedData.length > 0 &&
                computedData.map((item, index) => (React.createElement(Col, { key: index, span: 24 },
                    React.createElement("div", { className: "grid grid-cols-2 gap-1" },
                        React.createElement("button", { className: "text-grayScale04 text-left flex gap-1 items-center cursor-pointer", onClick: () => handleToggleCollapse(index) },
                            (item.childs || []).length > 0 && (React.createElement("span", null, isCollapse(index) ? (React.createElement(ChevronUpIcon, { width: 16, height: 16, color: "black" })) : (React.createElement(ChevronDownIcon, { width: 16, height: 16, color: "black" })))),
                            React.createElement("div", null, item.title)),
                        React.createElement("span", { className: "text-grayScale05 font-semibold text-right" },
                            React.createElement("div", null, item.value === 'loading' ? (React.createElement(Skeleton, { width: 60 })) : item.type === 'subtract' ? (`(Rp${currencyFormatter(item.value)})`) : item.type === 'string' ? (`${item?.value}`) : (`Rp${currencyFormatter(item.value)}`)))),
                    React.createElement("div", { className: "pl-[20px]", style: { display: isCollapse(index) ? 'block' : 'none' } }, (item.childs || []).length > 0 &&
                        item.childs.map((childItem, childIndex) => (React.createElement("div", { key: childIndex, className: "grid grid-cols-2 gap-1" },
                            React.createElement("span", { className: "text-grayScale04 text-left flex gap-1 items-center" },
                                React.createElement("div", null, childItem.title)),
                            React.createElement("span", { className: "text-grayScale05 font-semibold text-right" },
                                React.createElement("div", null, childItem.value === 'loading' ? (React.createElement(Skeleton, { width: 60 })) : childItem.type === 'subtract' ? (`(Rp${currencyFormatter(childItem.value)})`) : childItem.type === 'string' ? (`${childItem?.value}`) : (`Rp${currencyFormatter(childItem.value)}`)))))))))),
            React.createElement(Col, { span: 24, className: "grid grid-cols-2 gap-1" },
                React.createElement("span", { className: "text-grayScale04 text-left font-bold" }, "Total"),
                React.createElement("span", { className: "text-theme-primary-main font-bold text-right" }, isTotalLoading ? (React.createElement(Skeleton, { width: 100 })) : (`Rp${currencyFormatter(total !== null && total >= 0
                    ? total
                    : computedData.reduce((a, b) => {
                        if (typeof b.value === 'number') {
                            if (b.type === 'subtract') {
                                return a - b.value;
                            }
                            return a + b.value;
                        }
                        return a;
                    }, 0))}`))))));
};
export default BillSummary;
